import { Vue, Component, Watch } from 'vue-property-decorator'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'

@Component({
  name: 'GtrKeynoteSurveyView',
  computed: {
    ...mapState('surveys', ['keynoteChangelog', 'currentlyKeynoteDeployedLiveUUID', 'currentKeynoteDevUUID', 'keynoteSurvey'])
  },
  components: {
    changelog: ChangeLog
  }
})
export default class GtrKeynoteSurveyView extends Vue {
  data () {
    return {
      language: 'en',
      loading: false,
      table: {
        headers: [
          { text: 'Sort', value: 'order' },
          { text: 'Question text', value: 'question_text' },
          { text: 'Applies to', value: 'applies_to' },
          { text: 'Required', value: 'required' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      changeLog: {
        changeLog: [],
        currentDevUUID: null,
        currentlyDeployedLiveUUID: null
      },
      showAddKeynoteQuestionForm: false,
      editing: false,
      questionModel: {
        id: Vue.prototype.$uuid.v4(),
        type: 'text',
        rate: 0,
        isQuiz: 0,
        range: { start: 0, end: 0, other: [] },
        required: 1,
        label: {},
        storage_type: 'text',
        show_if: {
          global_type: '*',
          type: '*',
          field: '',
          operator: '',
          group_operator: '',
          group_items: [],
          value: ''
        },
        speaker_question: 0,
        correct_answer: null
      },
      choices: {
        languages: [],
        questions_type: [
          {
            text: 'Text Field',
            value: 'text'
          },
          {
            text: 'Radio Buttons',
            value: 'radio'
          },
          {
            text: 'Checkboxes',
            value: 'checkbox'
          },
          {
            text: 'Select',
            value: 'select'
          }],
        rated: [{
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }]
      },
      tableUpdated: false,
      surveyVersion: null
    }
  }

  async mounted () {
    await this.fetchKeynoteChangelog()
    await this.fetchCurrentKeynoteDevUUID()
    await this.fetchCurrentlyKeynoteDeployedLiveUUID()
    await this.fetchKeynoteSurvey()
  }

  @Watch('keynoteSurvey')
  onKeynoteSurveyChange (payload: any) {
    if (payload.page_data) {
      if (payload.page_data[0].required_percentage) {
        this.$data.required_percentage = payload.page_data[0].required_percentage
      }
      this.$data.table.items = payload.page_data[0].fields
      this.$data.table.items.forEach(question => {
        if (!question.range) {
          question.range = { start: 0, end: 0, other: [] }
        }
      })
    } else {
      this.$data.table.items = []
    }
  }

  @Watch('keynoteChangelog')
  onKeynoteChangelogChange (payload: any) {
    if (payload.data) {
      this.$data.changeLog.changeLog = payload.data
    }
  }

  @Watch('currentKeynoteDevUUID')
  onCurrentKeynoteDevUUIDChange (payload: any) {
    if (payload.dev) {
      this.$data.changeLog.currentDevUUID = payload.dev.uuid
    }
  }

  @Watch('currentlyKeynoteDeployedLiveUUID')
  onCurrentlyKeynoteDeployedLiveUUID (payload: any) {
    if (payload.live) {
      this.$data.changeLog.currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  @Watch('surveyVersion')
  async onSurveyVersionChange (payload: any) {
    if (payload !== 'default') {
      const response = await this.$store.dispatch('surveys/getSessionSurveyByUUID', { event_uuid: this.$route.params.event_uuid, survey_uuid: payload })
      const changeLogPageData = response.data.page_data[0]
      if (changeLogPageData) {
        this.$data.table.items = changeLogPageData.fields
        if (changeLogPageData.required_percentage) {
          this.$data.required_percentage = changeLogPageData.required_percentage
        }
        this.$data.changeLog.currentDevUUID = payload
      }
      Container.get(Notification).success('Survey version successfully changed.')
    }
  }

  private async fetchKeynoteChangelog () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getKeynoteChangelog', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCurrentKeynoteDevUUID () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getCurrentKeynoteDevUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'keynote' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCurrentlyKeynoteDeployedLiveUUID () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getCurrentlyKeynoteDeployedLiveUUID', { event_uuid: this.$route.params.event_uuid, type: 'survey', sub_type: 'keynote' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchKeynoteSurvey () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('surveys/getKeynoteSurvey', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.$data.loading = false
    }
  }

  handleAddOption () {
    this.$data.questionModel.range.other.push('')
  }

  handleDeleteOption (index) {
    this.$data.questionModel.range.other.splice(index, 1)
  }

  handleEditQuestion (question) {
    this.$data.questionModel = JSON.parse(JSON.stringify(question))
    this.$data.showAddKeynoteQuestionForm = true
    this.$data.editing = true
  }

  private handleShowAddKeynoteQuestionForm () {
    this.$data.showAddKeynoteQuestionForm = true
  }

  private handleCloseAddKeynoteQuestionForm () {
    this.$data.showAddKeynoteQuestionForm = false
  }

  handleDeleteQuestion (payload: any) {
    if (payload) {
      this.$data.table.items = this.$data.table.items.filter((question: any) => payload.id !== question.id)
      this.$data.tableUpdated = true
    }
  }

  handleTableUpdate (data: any) {
    this.$data.table.items = data
    this.$data.tableUpdated = true
  }

  updateSurveyVersion (uuid: string) {
    this.$data.surveyVersion = uuid
  }

  cancel () {
    if (this.$data.editing) {
      this.$data.editing = false
    }
    this.$data.showAddKeynoteQuestionForm = false
    this.cleanForm()
  }

  async discardChanges () {
    await this.fetchKeynoteSurvey()
    this.$data.tableUpdated = false
  }

  saveQuestion () {
    if (this.$data.editing) {
      const activeQuestionIndex = this.$data.table.items.findIndex(question => question.id === this.$data.questionModel.id)
      if (activeQuestionIndex >= 0) {
        this.$data.table.items[activeQuestionIndex] = this.$data.questionModel
      }
    } else {
      this.$data.table.items.push(this.$data.questionModel)
    }

    this.$data.showAddKeynoteQuestionForm = false
    this.$data.tableUpdated = true
    this.$data.editing = false
    this.cleanForm()
  }

  async save () {
    try {
      this.$data.loading = true
      const fields: any = this.$data.table.items.map(obj => ({ ...obj }))
      fields.map(field => {
        if (!field.range.start && !field.range.end && field.range.other.length === 0) {
          delete field.range
        }
        if (field.correct_answer) {
          delete field.rate
        } else {
          delete field.correct_answer
        }
        return field
      })
      const data = [{
        page_type: 'STANDARD',
		    prefix: '',
        suffix: '',
        settings: {},
        required_percentage: null,
        fields: fields
      }]

      if (this.$data.required_percentage) {
        data[0].required_percentage = this.$data.required_percentage
      } else {
        delete data[0].required_percentage
      }

      await this.$store.dispatch('surveys/saveKeynoteSurvey', {
        event_uuid: this.$route.params.event_uuid,
        type: 'survey',
        sub_type: 'keynote',
        data: data
      })
      Container.get(Notification).success('Survey successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$data.tableUpdated = false
      this.$data.editing = false
    }
  }

  cleanForm () {
    this.$data.questionModel = {
      id: Vue.prototype.$uuid.v4(),
      type: 'text',
      rate: 0,
      isQuiz: 0,
      range: { start: 0, end: 0, other: [] },
      required: 1,
      label: {},
      storage_type: 'text',
      show_if: {},
      speaker_question: 0,
      correct_answer: null
    }
  }

  async handleDeployed () {
    await this.fetchKeynoteChangelog()
    await this.fetchCurrentKeynoteDevUUID()
    await this.fetchCurrentlyKeynoteDeployedLiveUUID()
  }

  onChange (e: any) {
    if (e) {
      this.$data.questionModel.isQuiz = 1
      this.$data.questionModel.rate = false
      this.$data.questionModel.range.start = 0
      this.$data.questionModel.range.end = 0
    } else {
      this.$data.questionModel.isQuiz = 0
      this.$data.questionModel.correct_answer = null
    }
  }

  // SHOW IF

  onFieldValueChange (payload: string) {
    this.$data.showIf.model.field_or_option = payload.split('_')[0]
  }

  optionGroupOptions (selection: any) {
    return this.optionGroupOptionsByUUID()[selection]
  }

  getArrayFromOptionGroupOptionsByUUID (payload: any) {
    return this.optionGroupOptionsByUUID()[payload]
  }

  private optionGroupOptionsByUUID () {
    const items: any[] = []
    for (let i = 0; i < this.$data.optionGroups.length; i++) {
      items[this.$data.optionGroups[i].uuid] = []
      for (let x = 0; x < this.$data.optionGroups[i].options.length; x++) {
        items[this.$data.optionGroups[i].uuid].push({
          text: this.$data.optionGroups[i].options[x].name,
          value: this.$data.optionGroups[i].options[x].uuid
        })
      }
    }
    return items
  }

  handleAddShowIfGroup (payload: any) {
    payload.group_items.push({
      group_items: [],
      type: 'grouping',
      id: Vue.prototype.$uuid.v4()
    })
  }

  handleDeleteShowIfGroup (item: any, id: string) {
    item.group_items = item.group_items.filter((group: any) => group.id !== id)
    if (item.group_items.length === 0) {
      item.show_if = []
      item.use_show_if = false
      item.show_if_type = null
    }
  }

  handleAddGroupItem (group: any) {
    group.group_items.push({
      id: Vue.prototype.$uuid.v4()
    })
  }

  handleDeleteShowIf (group: any, id: string) {
    group.group_items = group.group_items.filter((item: any) => item.id !== id)
  }

  handleClearGroupItemFieldAndValue (item: any) {
    /**
     * The problem here was that I was accidentally hardcoding the property `value` as '' when it didn't exist yet. This was causing problems with
     * the items array when selecting field = option_criteria
     */
    if (item.hasOwnProperty('field')) {
      item.field = ''
    }
    if (item.hasOwnProperty('value')) {
      item.value = ''
    }
  }
}
